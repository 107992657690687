@import "~@/assets/scss/global.scss";

































































































































































































.page-top {
  padding: 6px 14px 0 14px;
}
